import React from 'react';

import AddToFavourites from './AddToFavourites';

import useLocalStorage from './hooks/useLocalStorage';

type Item = {
  slug: string;
  name: string;
  url: string;
}

const QuickFind = () => {

  const [favourites, setFavourites] = useLocalStorage<Item[]>('favourites', []);
  const [recentItems, setRecentItems] = useLocalStorage<Item[]>('recent', []);

  let allItems = favourites;

  recentItems.forEach(item => {
    if (!allItems.some(existing => existing.slug === item.slug)) {
      allItems.push(item);
    }
  });

  return (
    <div id="quickfind-list">
      {allItems.length > 0 && <>
        <p>Recent / Favourites</p>
        <ul>
          {allItems.map(item => (
            <li className="location-link" key={item.slug}><a href={`grounds/${item.url}`}>{item.name}</a>&nbsp;&nbsp;<AddToFavourites item={{name: item.name, slug: item.slug, url: item.url}} size={"small"}/></li>
          ))}
        </ul>
      </>}
    </div>
  );
}

export default QuickFind;